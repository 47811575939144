import { render, staticRenderFns } from "./Subhead.vue?vue&type=template&id=78e6b811&scoped=true&"
import script from "./Subhead.vue?vue&type=script&lang=js&"
export * from "./Subhead.vue?vue&type=script&lang=js&"
import style0 from "./Subhead.vue?vue&type=style&index=0&id=78e6b811&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78e6b811",
  null
  
)

export default component.exports
<!--  -->
<template>
  <div :class="['sub-head',size]">
    <img v-if="languageType=='ZN'" src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <img v-else src="@/assets/speaial_activity_img/imageEn/time_title.png" class="time-title" alt="">
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    size: {
      type: String,
      default: 'min',
    },
    languageType: {
      type: String,
      default: 'ZN',
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.sub-head {
  display: flex;
  justify-content: center;
}
.large {
  margin-top: rem(52);
  .time-title {
    height: rem(46);
  }
}
.min {
  .time-title {
    height: rem(38);
  }
}
</style>
<!-- 计数器 -->
<template>
  <div class='counter-view'>
    <div :class="[size,getStyle(item)]" v-for="(item,index) in num" :key="index">
      <img :src="getImageUrl(item)" alt="error">
    </div>
    <slot name="unit"></slot>
  </div>
</template>

<script>
export default {
  name: 'counter',
  props: {
    num: {
      type: String,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    getImageUrl(number) {
      let path = number == '.' ? 'dian' : number
      return require(`@/assets/speaial_activity_img/image/minNum/${path}.png`)
    },
    getStyle(number) {
      let style = number == '.' ? 'dian' : ''
      return style
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.counter-view {
  display: flex;
  align-items: flex-end;
}
.default {
  // width: rem(30);
  height: rem(50);
  margin: rem(1);
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
}
.dian {
  width: rem(20);
  height: rem(40);
}
</style>